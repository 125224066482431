
const DbData = () =>{
    return "DbDATA"
}
const DefSystem = (name) => {
    // const menuLangStrage = localStorage.getItem('MenuLang');
    // const controlMenuLangStrage = localStorage.getItem('ControlMenuLang');
    // const adminMenuLangStrage = localStorage.getItem('AdminMenuLang');
    // const menuStrage = localStorage.getItem('Menu');
    // const controlMenuStrage = localStorage.getItem('ControlMenu');
    // const adminMenuStrage = localStorage.getItem('AdminMenu');

    const localSave = (data) => {
        const timeDev = 1 * 60 * 1000 // 1分のミリ秒
        // const timePro = 1 * 1 * 60 * 60 * 1000// 1時間のミリ秒
        const time1DayPro = 1 * 1 * 1 * 24 * 60 * 60 * 1000// 1日のミリ秒
        const time30DayPro = 1 * 1 * 1 * 1 * 30 * 24 * 60 * 60 * 1000// 30日のミリ秒
        let expires = 0;
        if(name === 'defaultLanguage'){
            expires = Date.now() + time1DayPro; // 1分のミリ秒
        }
        if(name === 'publicLanguageSelect'){
            expires = Date.now() + time30DayPro; // 1時間のミリ秒
        }
        if(name === 'memberLanguageSelect'){
            expires = Date.now() + time30DayPro; // 1時間のミリ秒
        }
        if(name === 'underBanner'){
            expires = Date.now() + timeDev; // 1分のミリ秒
        }
        const arrValue = {"value":data.value, expires}
        localStorage.setItem(name, JSON.stringify(arrValue) );
    }

    const sendRequest = async (url,data) => {
        const params = new URLSearchParams(data).toString();
        const response = await fetch( `${url}?${params}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            // body: JSON.stringify(data)
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const responceData = await response.json();
        if(responceData){
            localSave(responceData.system[0]);
            return responceData.system[0];
        }
    }
    const requestDB = async() => {
        try {
                const data = { name }
                const resData = await sendRequest(`${process.env.REACT_APP_API_SYSTEM}`,data);
                return resData;
        } catch (error) {
            console.log(error)
            console.error("Failed to send the message: ", error);
        }
    }

    return requestDB();

};
const MenuData = (lv,langStrage) => {

    const getlocalStrageValue =(name) => {
        const jsData = localStorage.getItem(name);
        const data = JSON.parse(jsData);
        const currentTime = new Date().getTime();
        if(!jsData || data.expires < currentTime){
            localStorage.removeItem(name);
            return null;
        }else{
            return data.value;
        }
    }
    // const menuLangStrage = localStorage.getItem('MenuLang');
    // const controlMenuLangStrage = localStorage.getItem('ControlMenuLang');
    // const adminMenuLangStrage = localStorage.getItem('AdminMenuLang');
    // const menuStrage = localStorage.getItem('Menu');
    // const controlMenuStrage = localStorage.getItem('ControlMenu');
    // const adminMenuStrage = localStorage.getItem('AdminMenu');
    const menuLangStrage = getlocalStrageValue('MenuLang');
    const controlMenuLangStrage = getlocalStrageValue('ControlMenuLang');
    const adminMenuLangStrage = getlocalStrageValue('AdminMenuLang');
    const menuStrage = getlocalStrageValue('Menu');
    const controlMenuStrage = getlocalStrageValue('ControlMenu');
    const adminMenuStrage = getlocalStrageValue('AdminMenu');
    const defLang = getlocalStrageValue('defaultLanguage');

    const localSave = (data) => {
        const timePro = 1 * 1 * 60 * 60 * 1000// 1時間のミリ秒
        const expires = Date.now() + timePro;
        // console.log('data:'+data);
        const jsonData = JSON.stringify(data);
        // console.log('jsonData:'+jsonData);
        let arrValue =[];
        switch (lv) {
            case 1:
                arrValue = {"value":jsonData, expires}
                // localStorage.setItem('Menu', jsonData, { expires });
                localStorage.setItem('Menu', JSON.stringify(arrValue) );
                arrValue = {"value":langStrage, expires}
                // localStorage.setItem('MenuLang', langStrage, { expires });
                localStorage.setItem('MenuLang', JSON.stringify(arrValue) );
            break;
            case 100:
                arrValue = {"value":jsonData, expires}
                // localStorage.setItem('ControlMenu', jsonData, { expires });
                localStorage.setItem('ControlMenu', JSON.stringify(arrValue) );
                arrValue = {"value":langStrage, expires}
                // localStorage.setItem('ControlMenuLang', langStrage, { expires });
                localStorage.setItem('ControlMenuLang', JSON.stringify(arrValue) );
            break;
            case 1000:
                arrValue = {"value":jsonData, expires}
                // localStorage.setItem('AdminMenu', jsonData, { expires });
                localStorage.setItem('AdminMenu', JSON.stringify(arrValue) );
                arrValue = {"value":langStrage, expires}
                // localStorage.setItem('AdminMenuLang', langStrage, { expires });
                localStorage.setItem('AdminMenuLang', JSON.stringify(arrValue) );
            break;
            default:
                break;
        }
    }

    const sendRequest = async (url,data) => {
        const params = new URLSearchParams(data).toString();
        const response = await fetch( `${url}?${params}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            // body: JSON.stringify(data)
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const responceData = await response.json();
        const addLeadingSlash = (url) => {
            return url.startsWith('/') ? url : `/${url}`;
        };
        if(responceData){
            let varData;
            // console.log('p! varData:'+langStrage)
            if(langStrage === 'ja' || langStrage === 'JA'){
                varData = responceData.system.map(item => {
                    // console.log('length:'+item.labelJP.length)
                    let temData;
                    if(defLang ==='ja') temData = item.labelJP;
                    if(defLang ==='en') temData = item.labelEN;
                    if(defLang ==='zhCn') temData = item.labelCH;
                    return{
                        ...item,
                        label: item.labelJP.length > 0 ?  item.labelJP : temData,
                        url: addLeadingSlash(item.url) // 先頭に/がない場合に追加
                    }
                })
            };
            if(langStrage === 'en' || langStrage === 'EN'){
                varData = responceData.system.map(item => {
                    let temData;
                    if(defLang ==='ja') temData = item.labelJP;
                    if(defLang ==='en') temData = item.labelEN;
                    if(defLang ==='zhCn') temData = item.labelCH;
                    return{
                        ...item,
                        label: item.labelEN.length > 0 ?  item.labelEN : temData,
                        url: addLeadingSlash(item.url) // 先頭に/がない場合に追加
                    }
                })
            }
            if(langStrage === 'zhCn' || langStrage === 'ZHCN' || langStrage === 'cn' || langStrage === 'CN'){
                varData = responceData.system.map(item => {
                    let temData;
                    if(defLang ==='ja') temData = item.labelJP;
                    if(defLang ==='en') temData = item.labelEN;
                    if(defLang ==='zhCn') temData = item.labelCH;
                    return{
                        ...item,
                        label: item.labelCH.length > 0 ?  item.labelCH : temData,
                        url: addLeadingSlash(item.url) // 先頭に/がない場合に追加
                    }
                })
            }
            // console.log('varData:'+JSON.stringify(varData))
            localSave(varData);
            return varData;

            // console.log(adminMenuData)
        }
    }
    const requestDB = async() => {
        try {
                const level = lv;
                const data = { level }
                const resData = await sendRequest(`${process.env.REACT_APP_API_MENU}`,data);
                // console.log('resData:'+resData)
                return resData;
        } catch (error) {
            console.log(error)
            console.error("Failed to send the message: ", error);
        }
    }

    if(lv === 1){
        if(langStrage !== menuLangStrage || !menuStrage ){
            // DBから取得
            return requestDB();
        }else if(menuStrage){
            return menuStrage;
        }
    }else if(lv === 100){
        if(langStrage !== controlMenuLangStrage || !controlMenuStrage ){
            // DBから取得
            return requestDB();
        }else{
            // LocalStrageから取得
            return controlMenuStrage;
        }
    }else if(lv === 1000){
        if(langStrage !== adminMenuLangStrage || !adminMenuStrage ){
            // DBから取得
            return requestDB();
        }else{
            // LocalStrageから取得
            return adminMenuStrage;
        }
    }

};

const BottomMenuData = (langStrage) => {

    const getlocalStrageValue =(name) => {
        const jsData = localStorage.getItem(name);
        const data = JSON.parse(jsData);
        const currentTime = new Date().getTime();
        if(!jsData || data.expires < currentTime){
            localStorage.removeItem(name);
            return null;
        }else{
            return data.value;
        }
    }
    const menuLangStrage = getlocalStrageValue('MenuLang');
    const bottomMenuStrage = getlocalStrageValue('BottomMenu');
    const defLang = getlocalStrageValue('defaultLanguage');

    const localSave = (data) => {
        const timePro = 1 * 1 * 60 * 60 * 1000// 1時間のミリ秒
        const expires = Date.now() + timePro;
        // console.log('data:'+data);
        const jsonData = JSON.stringify(data);
        // console.log('jsonData:'+jsonData);
        let arrValue =[];
        arrValue = {"value":jsonData, expires}
        localStorage.setItem('BottomMenu', JSON.stringify(arrValue) );
    }

    const sendRequest = async (url) => {
        // const params = new URLSearchParams(data).toString();
        const response = await fetch( `${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            // body: JSON.stringify(data)
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const responceData = await response.json();
        const addLeadingSlash = (url) => {
            // URLがhttp://またはhttps://で始まっている場合、そのまま返す
            if (url.startsWith('http://') || url.startsWith('https://')) {
                return url;
            }
            // 先頭に/がない場合は追加する
            return url.startsWith('/') ? url : `/${url}`;
        };
        if(responceData){
            let varData;
            // console.log('p! varData:'+langStrage)
            if(langStrage === 'ja' || langStrage === 'JA'){
                varData = responceData.system.map(item => {
                    // console.log('length:'+item.labelJP.length)
                    let temData;
                    if(defLang ==='ja') temData = item.labelJP;
                    if(defLang ==='en') temData = item.labelEN;
                    if(defLang ==='zhCn') temData = item.labelCH;
                    return{
                        ...item,
                        label: item.labelJP.length > 0 ?  item.labelJP : temData,
                        url: addLeadingSlash(item.url) // 先頭に/がない場合に追加
                    }
                })
            };
            if(langStrage === 'en' || langStrage === 'EN'){
                varData = responceData.system.map(item => {
                    let temData;
                    if(defLang ==='ja') temData = item.labelJP;
                    if(defLang ==='en') temData = item.labelEN;
                    if(defLang ==='zhCn') temData = item.labelCH;
                    return{
                        ...item,
                        label: item.labelEN.length > 0 ?  item.labelEN : temData,
                        url: addLeadingSlash(item.url) // 先頭に/がない場合に追加
                    }
                })
            }
            if(langStrage === 'zhCn' || langStrage === 'ZHCN' || langStrage === 'cn' || langStrage === 'CN'){
                varData = responceData.system.map(item => {
                    let temData;
                    if(defLang ==='ja') temData = item.labelJP;
                    if(defLang ==='en') temData = item.labelEN;
                    if(defLang ==='zhCn') temData = item.labelCH;
                    return{
                        ...item,
                        label: item.labelCH.length > 0 ?  item.labelCH : temData,
                        url: addLeadingSlash(item.url) // 先頭に/がない場合に追加
                    }
                })
            }
            // console.log('varData:'+JSON.stringify(varData))
            localSave(varData);
            return varData;

            // console.log(adminMenuData)
        }
    }
    const requestDB = async() => {
        try {
                const resData = await sendRequest(`${process.env.REACT_APP_API_BOTTOMMENU}`);
                // console.log('resData:'+resData)
                return resData;
        } catch (error) {
            console.log(error)
            console.error("Failed to send the message: ", error);
        }
    }

    if(langStrage !== menuLangStrage || !bottomMenuStrage ){
        // DBから取得
        return requestDB();
    }else if(bottomMenuStrage){
        return bottomMenuStrage;
    }

};

const LevelData = (langStrage) => {

    const getlocalStrageValue =(name) => {
        const jsData = localStorage.getItem(name);
        const data = JSON.parse(jsData);
        const currentTime = new Date().getTime();
        if(!jsData || data.expires < currentTime){
            localStorage.removeItem(name);
            return null;
        }else{
            return data.value;
        }
    }
    // const langStrage = localStorage.getItem('Lang');
    // const levelLangStrage = localStorage.getItem('LevelLang');
    // const levelStrage = localStorage.getItem('Level');

    const levelLangStrage = getlocalStrageValue('LevelLang');
    const levelStrage = getlocalStrageValue('Level');

    const localSave = (data) => {
        const timePro = 1 * 1 * 60 * 60 * 1000// 1時間のミリ秒
        const expires = Date.now() + timePro;
        const jsonData = JSON.stringify(data);
        let arrValue = {"value":jsonData, expires}
        localStorage.setItem('Level', JSON.stringify(arrValue) );
        // localStorage.setItem('Level', jsonData, { expires });
        arrValue = {"value":langStrage, expires}
        // localStorage.setItem('LevelLang', langStrage, { expires });
        localStorage.setItem('LevelLang', JSON.stringify(arrValue) );
    }

    const sendRequest = async (url) => {
        const response = await fetch( url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const responceData = await response.json();
        if(responceData){
            let varData;
            if(langStrage === 'ja' || langStrage === 'JA'){
                varData = responceData.system.map(item => ({
                    ...item,
                    name: item.nameJP
                }))
            }
            if(langStrage === 'en' || langStrage === 'EN'){
                varData = responceData.system.map(item => ({
                    ...item,
                    name: item.nameEN
                }))
            }
            if(langStrage === 'zhCn' || langStrage === 'ZHCN' || langStrage === 'cn' || langStrage === 'CN'){
                varData = responceData.system.map(item => ({
                    ...item,
                    name: item.nameCH
                }))
            }
            localSave(varData);
            return varData;
        }
    }
    const requestDB = async() => {
        try {
                const resData = await sendRequest(`${process.env.REACT_APP_API_LEVEL}`);
                return resData;
        } catch (error) {
            console.log(error)
            console.error("Failed to send the message: ", error);
        }
    }

    if(langStrage !== levelLangStrage || !levelStrage ){
        // DBから取得
        // console.log("DB");
        return requestDB();
    }else if(levelStrage){
        // console.log("Strage");
        return levelStrage;
    }

};

const ContentData = (address,langStrage) => {
    const lsData = localStorage.getItem('defaultLanguage');
    let defLang = '';
    if(lsData){
        const data = JSON.parse(lsData);
        defLang = data.value;
    }
    const sendRequest = async (url) => {
        const response = await fetch( url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            // body: JSON.stringify(data)
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const responceData = await response.json();
        if(responceData){
            let varData;
            if(langStrage === 'ja' || langStrage === 'JA'){
                varData = responceData.system.map(item => {
                    let temp1Data, temp2Data, temp3Data, tempTitleData;
                    if (defLang === 'ja') {
                        temp1Data = item.content1JP;
                        temp2Data = item.content2JP;
                        temp3Data = item.content3JP;
                        tempTitleData = item.titleJP;
                    } else if (defLang === 'en') {
                        temp1Data = item.content1EN;
                        temp2Data = item.content2EN;
                        temp3Data = item.content3EN;
                        tempTitleData = item.titleEN;
                    } else if (defLang === 'zhcn') {
                        temp1Data = item.content1CH;
                        temp2Data = item.content2CH;
                        temp3Data = item.content3CH;
                        tempTitleData = item.titleCH;
                    }
                    return{
                        ...item,
                        content1: item.titleJP !== null ? item.content1JP : temp1Data,
                        content2: item.titleJP !== null ? item.content2JP : temp2Data,
                        content3: item.titleJP !== null ? item.content3JP : temp3Data,
                        title: item.titleJP !== null ? item.titleJP : tempTitleData
                    };
                })
            }
            if(langStrage === 'en' || langStrage === 'EN'){
                varData = responceData.system.map(item => {
                    let temp1Data, temp2Data, temp3Data, tempTitleData;
                    if (defLang === 'ja') {
                        temp1Data = item.content1JP;
                        temp2Data = item.content2JP;
                        temp3Data = item.content3JP;
                        tempTitleData = item.titleJP;
                    } else if (defLang === 'en') {
                        temp1Data = item.content1EN;
                        temp2Data = item.content2EN;
                        temp3Data = item.content3EN;
                        tempTitleData = item.titleEN;
                    } else if (defLang === 'zhcn') {
                        temp1Data = item.content1CH;
                        temp2Data = item.content2CH;
                        temp3Data = item.content3CH;
                        tempTitleData = item.titleCH;
                    }
                    return{
                        ...item,
                        content1: item.titleEN !== null ? item.content1EN : temp1Data,
                        content2: item.titleEN !== null ? item.content2EN : temp2Data,
                        content3: item.titleEN !== null ? item.content3EN : temp3Data,
                        title: item.titleEN !== null ? item.titleEN : tempTitleData
                    };
                })
            }
            if(langStrage === 'zhCn' || langStrage === 'ZHCN' || langStrage === 'cn' || langStrage === 'CN'){
                varData = responceData.system.map(item => {
                    let temp1Data, temp2Data, temp3Data, tempTitleData;

                    if (defLang === 'ja') {
                        temp1Data = item.content1JP;
                        temp2Data = item.content2JP;
                        temp3Data = item.content3JP;
                        tempTitleData = item.titleJP;
                    } else if (defLang === 'en') {
                        temp1Data = item.content1EN;
                        temp2Data = item.content2EN;
                        temp3Data = item.content3EN;
                        tempTitleData = item.titleEN;
                    } else if (defLang === 'zhcn') {
                        temp1Data = item.content1CH;
                        temp2Data = item.content2CH;
                        temp3Data = item.content3CH;
                        tempTitleData = item.titleCH;
                    }
                    return{
                        ...item,
                        content1: item.titleCH !== null ? item.content1CH : temp1Data,
                        content2: item.titleCH !== null ? item.content2CH : temp2Data,
                        content3: item.titleCH !== null ? item.content3CH : temp3Data,
                        title: item.titleCH !== null ? item.titleCH : tempTitleData
                    };
                })
            }
            return varData;
        }
    }
    const requestDB = async() => {
        try {
                // const data = { address }
                const resData = await sendRequest(`${process.env.REACT_APP_API_CONTENT}?address=${address}`);
                return resData;
        } catch (error) {
            console.log(error)
            console.error("Failed to get the content: ", error);
        }
    }

    if(address){
        return requestDB();
    }

};
export default DbData
export { LevelData, MenuData, BottomMenuData, ContentData, DefSystem }