import React, { useEffect, useState } from 'react';
import Header from 'components/contentsParts/Header';
import Footer from 'components/contentsParts/Footer';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

const CompanyLoginPage = () =>{
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const page = query.get('page');
    const logoImage = process.env.REACT_APP_BASE_URL+"/static/media/logo3.64bba8a1181b2d3ddccc.png";
    const [ memberPassword, setMemberPassword ] = useState('');
    const [ errorMessVisibility, setErrorMessageVisibility ] = useState(false);


    const sendRequest = async (url,data) => {
        const params = new URLSearchParams(data).toString();
        const response = await fetch( `${url}?${params}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            // body: JSON.stringify(data)
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const responceData = await response.json();
        if(responceData){
            // console.log(responceData.system[0].value)
            setMemberPassword(responceData.system[0].value);
        }
    }

    const requestDB = async(name) => {
        try {
            const data = { name }
            const resData = await sendRequest(`${process.env.REACT_APP_API_SYSTEM}`,data);
            return resData;
        } catch (error) {
            console.log(error)
            console.error("Failed to send the message: ", error);
        }
    }
    const checkPaswordFunction = (data) =>{
        // console.log(data.password)
        if(memberPassword === data.password) {
            const arrValue = {"value":'true'}

            sessionStorage.setItem('CompanyPassword', JSON.stringify(arrValue) );
            const naviPath = '/'+page;
            navigate(naviPath);
            // navigate('/companylogin?page=test');
        }else{
            // エラーメッセ維持処理
            setErrorMessageVisibility(true);
            setTimeout(() => {
                setErrorMessageVisibility(false);
            }, 3000);
        }

    }
    useEffect(() => {
        requestDB('memberPassword');
    },[])
    // ユーザー編集データーチェック
    const formik = useFormik({
        initialValues: {
            password:''
        },
        validate: (data) => {
            let errors = {};
            return errors;
        },
        onSubmit: (data) => {
            checkPaswordFunction(data);
        }
    });

    return(
        <div className="pb-20">
            <Header />
            <div className="p-8 pt-[90px] fixed w-full h-full top-0 left-0 right-0 z-[60] bg-rose-200/90">
                <div className='border border-blue-800 rounded-xl p-6 w-fit m-auto'>
                    <div className='w-[200px] mx-auto'>
                        <img src={logoImage} alt="Logo" />
                    </div>
                    <form 
                        onSubmit={formik.handleSubmit} 
                    >
                        <div className='text-lg font-bold mx-auto w-fit mt-6'>
                            {t('companyLogin')}
                        </div>
                        <div className='w-fit mx-auto mt-4'>
                            {t('needPassword')}
                        </div>
                        <div className='w-fit mx-auto pt-6 flex flex-col'>
                        <InputText
                            placeholder="password"
                            onChange={(e) => {
                                formik.setFieldValue('password', e.target.value);
                            }}
                        />
                        {(errorMessVisibility) && (
                            <div className='my-2 text-red-700 font-bold'>
                                {t('companyPassEroor')}
                            </div>
                        )}
                        <div className='w-fit mx-auto mt-4'>
                            <Button 
                                label={t('login')}
                                type="submit"
                            />
                        </div>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CompanyLoginPage