import React, { useState, useEffect } from 'react';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';
import { MenuData,BottomMenuData } from '../../components/contentsParts/DbData';

const Footer = () =>{
    const [isScrolled, setIsScrolled] = useState(false);
    const [ language, setLanguage ] = useState('')
    const [ menuData, setMenuData] = useState([]);
    const [ bottomMenuData, setBottomMenuData] = useState([]);
    const [ token, setToken ] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('token');
        
        if(token){
            setToken(token);
        }
        const lStrage = localStorage.getItem('Lang');
        const data = JSON.parse(lStrage);
        const langStrage =data.value;
        switch(langStrage){
            case 'zhCn': setLanguage(langStrage);
            break;
            case 'en': setLanguage(langStrage);
            break;
            case 'ja':
            default: setLanguage('ja');
            break;
        }
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        // スクロールイベントリスナーを追加
        window.addEventListener('scroll', handleScroll);

        // クリーンアップ関数でイベントリスナーを削除
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    },[])

    useEffect(() => {
        const getMenuFunction = async () => {
            const getData = await MenuData(1,language);
            if(getData){
                setMenuData(getData);
                try {
                    setMenuData(JSON.parse(getData));
                } catch (e) {
                }
            }
        }
        
        if (language) {
            getMenuFunction();
        }
    },[language,token]);

    useEffect(() => {
        const getMenuFunction = async () => {
            const getData = await BottomMenuData(language);
            if(getData){
                setBottomMenuData(getData);
                try {
                    setBottomMenuData(JSON.parse(getData));
                } catch (e) {
                }
            }
        }
        
        if (language) {
            getMenuFunction();
        }
    },[language]);
    const topBackLinkfunction = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    const scrollToVisible = () => {
        if(isScrolled){
            return null;
        }else{
            return '!hidden';
        }
    }
    const BottomFunction = () =>{
        // console.log(bottomMenuData);
        // return (
        //             <div className="">
        //                 <Menu 
        //                     model={bottomMenuData} 
        //                     className="!w-full !bg-inherit !border-none justify-between"
        //                     pt={{
        //                         root: ' ',
        //                         menu: '!m-0 !p-0 list-none outline-none flex flex-row !w-full',
        //                         content: 'text-pink-700  hover:!text-wpink-800 hover:font-bold hover:!bg-inherit !py-0 mb-1 hover:mb-0 text-xs',
        //                         menuitem: 'hover:border-b-4 hover:!border-pink-800/80 hover:!text-pink-800 hover:!border-double hover:!bg-inherit  mb-1 hover:mb-0',
        //                         label:'hover:!text-pink-800  text-pink-700',
        //                         icon: 'hover:!text-pink-800 text-xs pi-fw  text-pink-700',
        //                         action:'!py-0 !px-2 hover:!no-underline flex flex-col basis-1/5 !w-full'
        //                     }}
        //                 />
        //             </div>
        //         );
        return bottomMenuData.map((item,index)=>{
            // 中心が３の場合に
            if(item.sort === 3){
                return (
                    <div className='flex flex-col
                        bg-rose-500 pt-3 pb-3 pl-4 pr-4 rounded-full
                        ' 
                        key={index}
                        style={{
                            marginTop:"-35px"
                        }}>
                        <a href={item.url} target="_self" rel="noopener noreferrer" 
                        className="
                            text-rose-400 font-bold
                            hover:!text-pink-800 hover:font-bold
                        " 
                        style={{
                            textShadow:"1px 1px #ffffff,-1px -1px #ffffff,-1px 1px #ffffff,1px -1px #ffffff"
                        }}
                        >
                            <div className={item.icon}
                                style={{
                                    fontSize:"1.5rem"
                                }}
                            />
                            <div className="label">
                                {item.label}
                            </div>
                        </a>
                    </div>
                )
            }
            return (
                <div className='flex flex-col h-14' key={index}>
                    <a href={item.url} target="_self" rel="noopener noreferrer" 
                    className="
                        text-rose-400 font-bold
                        hover:!text-pink-800 hover:font-bold
                    " 
                    style={{
                        textShadow:"1px 1px #ffffff,-1px -1px #ffffff,-1px 1px #ffffff,1px -1px #ffffff"
                    }}
                    >
                        <div className={item.icon}
                                style={{
                                    fontSize:"1.3rem"
                                }}/>
                        <div className="label">
                            {item.label}
                        </div>
                    </a>
                </div>
            )
        })
    }

    return(
        <>
        <footer className="
            fixed bottom-0 left-0 right-0 
            text-center bg-white text-sm text-gray-600 !shadow 
            h-14"
            style={{
                backgroundImage:'linear-gradient(-9deg, rgb(251 239 241), rgb(250 233 238) 34%, rgb(246 181 203) 72%)'
            }}
        >
            <div className="w-full mx-auto !w-full" >
                {/* Bottom menu */}
                <div className="flex justify-between 
                items-center content-center 
                !max-h-15 !min-h-6 
                pt-2 ml-3 mr-3 
                text-pink-800 !w-auto">
                    <BottomFunction />
                </div>
                <span className="
                    hidden md:block
                    text-xs pt-2 md:pt-1 pb-1 text-pink-800">
                    Copyright © 2024 kougakunavi.jp All Rights Reserved.
                </span>
            </div>
        </footer>
        <Button
            className={`!fixed bottom-[110px] md:bottom-[80px] right-4 
                !rounded-full !bg-pink-500 opacity-75 border-2 border-white
                font-semibold
                text-white
                !pl-3 !pt-2
                !text-xs
                ${scrollToVisible()}`}
            label='Top'
            icon='pi pi-angle-double-up'
            onClick={topBackLinkfunction}
            plain='true'
            pt={{

            }}
        />
        </>
    )
}
export default Footer