import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Template from 'components/contentsParts/Template';

const IndexPage = () =>{
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [ token , setToken] = useState('');

    useEffect(() => {
        setToken(sessionStorage.getItem('token'));
        // const fetchData = async () => {
        //     const val = localStorage.getItem('Lang');
        //     if(val){
        //         const jsData = JSON.parse(val);
        //         const data = await ContentData('index',jsData.value);
        //     }
        // }
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        // fetchData();
        window.addEventListener('resize', handleResize);
        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    },[])

    return Template('index','');
}

export default IndexPage